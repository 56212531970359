import axios from "axios";

export async function createCheckoutSession(
  priceId: string,
  accessToken: string
) {
  return axios.post(
    `${process.env.NEXT_PUBLIC_APP_API_URL}/stripe/checkout`,
    {
      priceId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export async function createPortalLink(accessToken: string) {
  return axios.post(
    `${process.env.NEXT_PUBLIC_APP_API_URL}/stripe/portal`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export async function checkSubscriptionStatus(accessToken: string) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_APP_API_URL}/subscription/me`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}
