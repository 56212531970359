
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Button, Checkbox, Col, Divider, Form, Input, Row } from "antd";
import Link from "next/link";
import AuthLayout from "../layouts/auth.layout";
import { Typography } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import { login } from "../services/auth.service";
import useUserStore from "../zustand/useUserStore";
import { message } from "antd";
import { useEffect, useState } from "react";
import GoogleLogoSVG from "../public/images/googleIcon.svg";
import { checkSubscriptionStatus } from "../services/subscription.service";
import EmailSVG from "../public/images/email.svg";
import PasswordSVG from "../public/images/password.svg";
import { ErrorMessageIcon, SuccessMessageIcon } from "../constants/icons";
import useTranslation from "next-translate/useTranslation";

const API_URL = process.env.NEXT_PUBLIC_APP_API_URL!;

const { Title, Text } = Typography;

const Login = () => {
  const { t, lang } = useTranslation("login");
  const router = useRouter();

  const token = useUserStore((state: any) => state.token);
  const isRememberMe = useUserStore((state: any) => state.isRememberMe);
  const setUser = useUserStore((state: any) => state.setUser);
  const setToken = useUserStore((state: any) => state.setToken);
  const expiresAt = useUserStore((state: any) => state.expiresAt);
  const setExpiresAt = useUserStore((state: any) => state.setExpiresAt);
  const setIsRememberMe = useUserStore((state: any) => state.setIsRememberMe);
  const setSubscription = useUserStore((state: any) => state.setSubscription);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    // If valid token,
    if (token !== "" && new Date(expiresAt) > new Date()) {
      router.push("/find");
    }
  }, [token]);

  const handleRememberMeExpiry = (numDays?: number) => {
    // Remember me.
    // If remember me clicked, the token TTL is extended.
    let expiresInDays = 1;
    if (numDays) {
      expiresInDays = numDays;
    } else {
      expiresInDays = rememberMe ? 7 : 1;
    }
    const expiresAt = new Date();
    // expiresAt.setSeconds(expiresAt.getSeconds() + expires_in);
    expiresAt.setDate(expiresAt.getDate() + expiresInDays);
    setExpiresAt(expiresAt);
  };

  // on clicked login button.
  const onFinish = async (values: any) => {
    setIsLoading(true);
    try {
      const result = await login(values);
      if (result.data.jwt != null || result.data.jwt != undefined) {
        // Get subscription details.
        try {
          const subscriptionResult = await checkSubscriptionStatus(
            result.data.jwt
          );
          setSubscription(subscriptionResult.data.subscription);
        } catch (e: any) {
          // message.error(`Error getting subscription details ${e.message}`);
        }

        handleRememberMeExpiry();
        //----------------------------------------------------//

        // update state.
        setUser(result.data.user);
        setToken(result.data.jwt);
        // setIsRememberMe(rememberMe);

        // message.success(currentLocale.loginPage.loginSuccess);
        message.success({
          icon: (
            <div>
              <SuccessMessageIcon />
            </div>
          ),
          content: t("login-success"),
        });
      }
    } catch (e: any) {
      if (
        e?.response?.data?.error?.status === 400 &&
        e?.response?.data?.error?.message ===
          "Your account email is not confirmed"
      ) {
        message.error({
          icon: (
            <div>
              <ErrorMessageIcon />
            </div>
          ),
          content: t("email-not-confirmed"),
        });
      } else {
        message.error({
          icon: (
            <div>
              <ErrorMessageIcon />
            </div>
          ),
          content:
            e?.response?.data?.error?.message || e?.message || "Unknown Error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  // on clicked login with google button.
  const onGoogleLogin = () => {
    // Expiry TTL for google login is 7 days.
    handleRememberMeExpiry(7);

    window.location.href = `${API_URL}/connect/google`;
  };

  const handleRememberMeChanged = (e: any) => {
    setRememberMe(e.target.checked);
  };

  const defaultValue = { identifier: "", password: "", rememberMe: false };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Text style={{ fontSize: "28px", fontFamily: "Inter" }}>
          {t("nice")}
        </Text>
      </div>

      {/* <Text type="secondary" style={{ fontSize: "1.5em" }}>
          {currentLocale.loginPage.enterCredentials}
        </Text> */}
      <Button
        icon={<GoogleLogoSVG />}
        onClick={onGoogleLogin}
        type="primary"
        htmlType="submit"
        style={{
          width: "100%",
          height: "40px",
          fontSize: "16px",
          color: "black",
          backgroundColor: "white",
          borderColor: "lightgrey",
          marginTop: "3%",
          marginBottom: "3%",
          borderRadius: "8px",
          justifyContent: "center",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <Text style={{ padding: "0px 10px 0px 10px" }}>{t("google")}</Text>
      </Button>
      <Divider plain style={{ color: "lightgrey", fontSize: "10px" }}>
        {t("or")}
      </Divider>
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        initialValues={defaultValue}
      >
        <Form.Item
          name="identifier"
          // label="E-mail"
          hasFeedback
          rules={[
            {
              type: "email",
              message: t("invalid-email"),
            },
            {
              required: true,
              message: t("input-email"),
            },
          ]}
        >
          <Input
            id="loginEmailInput"
            // prefix={<MailOutlined className="site-form-item-icon" />}
            prefix={<EmailSVG />}
            placeholder={t("email-placeholder")}
            style={{
              borderRadius: "8px",
              height: "45px",
              fontSize: "16px",
            }}
          ></Input>
        </Form.Item>

        <Form.Item
          hasFeedback
          name="password"
          // label="Password"
          rules={[
            {
              required: true,
              message: t("input-password"),
            },
          ]}
        >
          <Input.Password
            id="loginPasswordInput"
            // prefix={<LockOutlined className="site-form-item-icon" />}
            prefix={<PasswordSVG />}
            placeholder={t("password-placeholder")}
            style={{
              borderRadius: "8px",
              height: "45px",
              fontSize: "16px",
            }}
          ></Input.Password>
        </Form.Item>

        <Row style={{ justifyContent: "space-between", fontSize: "12px" }}>
          <Form.Item name="rememberMe" valuePropName="checked">
            <Checkbox
              onChange={handleRememberMeChanged}
              style={{ fontSize: "12px" }}
            >
              {t("remember")}
            </Checkbox>
          </Form.Item>

          <Link href="/auth/forgot-password">
            <a style={{ color: "#1890ff" }}>{t("forgot")}</a>
          </Link>
        </Row>

        <Form.Item>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              height: "40px",
              fontSize: "18px",
              borderRadius: "8px",
            }}
          >
            {t("login")}
          </Button>
        </Form.Item>
      </Form>
      <div style={{ fontSize: "12px" }}>
        <span>{t("continuing")} </span>
        <br />
        <Link href="https://www.leadsnow.app/terms-of-use">
          <a target="_blank" style={{ color: "#1890ff", marginRight: "5px" }}>
            {t("tos")}
          </a>
        </Link>
        <span style={{ marginRight: "5px" }}>&</span>
        <Link href="https://www.leadsnow.app/privacy-policy">
          <a target="_blank" style={{ color: "#1890ff" }}>
            {t("privacy")}
          </a>
        </Link>
      </div>
      <div style={{ fontSize: "16px", marginTop: "20px" }}>
        {t("not-use")}
        <Link href="/signup">
          <a style={{ color: "#1890ff" }}> {t("create")}</a>
        </Link>
      </div>

      <div style={{ marginTop: "12px", fontSize: "12px" }}>
        <Text style={{ color: "#585757" }}>© 2022 Leadsnow Oy</Text>
      </div>
    </>
  );
};
Login.layout = AuthLayout;
export default Login;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  